import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
// Composition API
import { defineComponent, reactive, onMounted, watch } from 'vue';
import { useInit, formatDate } from '@/services/shared/utils';
import { SocialType, EmployeeStatusEnum, EmployeeModel } from '@/services/features/employee/employee.model'; // Định nghĩa section của component bằng Typescript

var UserProfilePageSection;

(function (UserProfilePageSection) {
  UserProfilePageSection["PROFILE_INFO"] = "info";
  UserProfilePageSection["PROFILE_EDIT"] = "edit";
  UserProfilePageSection["PROFILE_BUSINESS"] = "business";
  UserProfilePageSection["CHANGE_PASSWORD"] = "change-password";
})(UserProfilePageSection || (UserProfilePageSection = {})); // Định nghĩa data type của component [props] bằng Typescript


var UserProfileInfoComponentProps = function UserProfileInfoComponentProps() {
  _classCallCheck(this, UserProfileInfoComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var UserProfileInfoComponentData = function UserProfileInfoComponentData() {
  _classCallCheck(this, UserProfileInfoComponentData);
}; // Component chính


export default defineComponent({
  name: 'UserProfileInfoComponent',
  components: {// IconColorVectorSvg
  },
  props: {
    user: {
      type: EmployeeModel,
      default: null
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false,
      isAccountVerified: false,
      socialFacebook: null,
      socialViber: null,
      socialZalo: null,
      socialInstagram: null,
      socialTiktok: null,
      projectPaticipated: null
    }); // Function

    var goToEditComponent = function goToEditComponent() {
      emit('changeTab', UserProfilePageSection.PROFILE_EDIT);
    };

    var goToBusinessProfileComponent = function goToBusinessProfileComponent() {
      emit('changeTab', UserProfilePageSection.PROFILE_BUSINESS);
    };

    var getProjectPaticipated = function getProjectPaticipated() {
      if (props.user.businessProfile && props.user.businessProfile.projectPaticipated && props.user.businessProfile.projectPaticipated.length) {
        data.projectPaticipated = props.user.businessProfile.projectPaticipated.map(function (item) {
          return item.name;
        }).join(', ');
      }
    };

    var getSocialUrl = function getSocialUrl() {
      if (props.user.socialUrls) {
        data.socialFacebook = props.user.socialUrls.find(function (item) {
          return item.name === SocialType.SOCIAL_FACEBOOK;
        });
        data.socialViber = props.user.socialUrls.find(function (item) {
          return item.name === SocialType.SOCIAL_VIBER;
        });
        data.socialZalo = props.user.socialUrls.find(function (item) {
          return item.name === SocialType.SOCIAL_ZALO;
        });
        data.socialInstagram = props.user.socialUrls.find(function (item) {
          return item.name === SocialType.SOCIAL_INSTAGRAM;
        });
        data.socialTiktok = props.user.socialUrls.find(function (item) {
          return item.name === SocialType.SOCIAL_TIKTOK;
        });
      }
    }; // Watch


    watch(function () {
      return props.user;
    }, function () {
      getProjectPaticipated();
      getSocialUrl();
      data.isAccountVerified = props.user.status === EmployeeStatusEnum.INIT || props.user.status === EmployeeStatusEnum.WAITING;
    }); // Life cylce

    onMounted(function () {
      if (!props.user) {
        return;
      }

      data.isAccountVerified = props.user.status === EmployeeStatusEnum.INIT || props.user.status === EmployeeStatusEnum.WAITING;
      getSocialUrl();
      getProjectPaticipated();
    });
    return {
      // Enum
      UserProfilePageSection: UserProfilePageSection,
      EmployeeStatusEnum: EmployeeStatusEnum,
      // Component props
      props: props,
      // Component reactive data
      data: data,
      // Methods
      goToEditComponent: goToEditComponent,
      formatDate: formatDate,
      goToBusinessProfileComponent: goToBusinessProfileComponent
    };
  }
});
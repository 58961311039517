import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive } from 'vue';
import { useInit } from '@/services/shared/utils';
import { Form, Field } from 'vee-validate'; // https://vee-validate.logaretm.com/v4/guide/composition-api/validation

import * as yup from 'yup';
import { EmployeeStatusEnum } from '@/services/features/employee/employee.model'; // Định nghĩa data type của component bằng Typescript

var UserProfileComponentType;

(function (UserProfileComponentType) {
  UserProfileComponentType["UserProfileInfoComponent"] = "UserProfileInfoComponent";
  UserProfileComponentType["UserProfileEditComponent"] = "UserProfileEditComponent";
  UserProfileComponentType["UserProfileChangePasswordComponent"] = "UserProfileChangePasswordComponent";
  UserProfileComponentType["UserProfileBusinessProfileComponent"] = "UserProfileBusinessProfileComponent";
})(UserProfileComponentType || (UserProfileComponentType = {})); // Định nghĩa data type của component [data] bằng Typescript


var UserProfileChangePasswordComponentData = function UserProfileChangePasswordComponentData() {
  _classCallCheck(this, UserProfileChangePasswordComponentData);
}; // Component chính


export default defineComponent({
  name: 'UserProfileChangePasswordComponent',
  components: {
    Form: Form,
    Field: Field
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: false
    });
    var formData = {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    };
    var formValidation = yup.object({
      oldPassword: yup.string().label('Password').required('Vui lòng điền mật khẩu cũ').min(8, 'Độ dài tối thiểu 8 ký tự'),
      password: yup.string().label('New Password').required('Vui lòng điền mật khẩu mới').min(8, 'Độ dài tối thiểu 8 ký tự'),
      confirmPassword: yup.string().label('Confirm Password').required('Vui lòng xác nhận mật khẩu mới').oneOf([yup.ref('password')], 'Mật khẩu mới không khớp')
    }); // Function

    var onInvalidSubmit = function onInvalidSubmit() {
      emit('onChangePassword', false);
    };

    var onChangePassword = function onChangePassword(values) {
      emit('onChangePassword', {
        oldPassword: values.oldPassword,
        password: values.password
      });
    };

    return {
      // Enum
      UserProfileComponentType: UserProfileComponentType,
      EmployeeStatusEnum: EmployeeStatusEnum,
      // Component reactive data
      data: data,
      // Form + Fields
      formValidation: formValidation,
      formData: formData,
      // Methods
      onChangePassword: onChangePassword,
      onInvalidSubmit: onInvalidSubmit
    };
  }
});
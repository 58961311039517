import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
// Composition API
import { defineComponent, reactive, onMounted, watch, ref } from 'vue';
import { useInit, formatDate } from '@/services/shared/utils';
import { SocialType, EmployeeStatusEnum, EmployeeModel } from '@/services/features/employee/employee.model';
import * as htmlToImage from 'html-to-image';
import * as fs from 'file-saver';
import QRCode from 'qrcode'; // Định nghĩa section của component bằng Typescript

var UserProfilePageSection;

(function (UserProfilePageSection) {
  UserProfilePageSection["PROFILE_INFO"] = "info";
  UserProfilePageSection["PROFILE_EDIT"] = "edit";
  UserProfilePageSection["PROFILE_BUSINESS"] = "business";
  UserProfilePageSection["CHANGE_PASSWORD"] = "change-password";
})(UserProfilePageSection || (UserProfilePageSection = {}));

var SortOrderSocialUrlsSection = [SocialType.SOCIAL_ZALO, SocialType.SOCIAL_FACEBOOK, SocialType.SOCIAL_VIBER, SocialType.SOCIAL_WEBSITE, SocialType.SOCIAL_TIKTOK, SocialType.SOCIAL_INSTAGRAM]; // Định nghĩa data type của component [props] bằng Typescript

var UserBusinessProfileComponentProps = function UserBusinessProfileComponentProps() {
  _classCallCheck(this, UserBusinessProfileComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var UserBusinessProfileComponentData = function UserBusinessProfileComponentData() {
  _classCallCheck(this, UserBusinessProfileComponentData);
}; // Component chính


export default defineComponent({
  name: 'UserBusinessProfileComponent',
  components: {},
  props: {
    user: {
      type: EmployeeModel,
      default: null
    },
    slugId: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base,
        isLoggedIn = _useInit.isLoggedIn,
        currentUser = _useInit.currentUser;

    var data = reactive({
      isLoading: false,
      socialUrls: [],
      projectPaticipated: null,
      isCurrentUser: props.slugId ? false : true,
      clipboard: '',
      sortOrderSocialUrls: SortOrderSocialUrlsSection
    }); // Function

    var goToInfoComponent = function goToInfoComponent() {
      emit('changeTab', UserProfilePageSection.PROFILE_INFO);
    };

    var getBackgroundImage = function getBackgroundImage(url) {
      if (url) {
        return "url('".concat(url, "')");
      }

      return "url(".concat(require('@/assets/feature/business-profile/bg_project_default.svg'), ")");
    };

    var getProjectPaticipated = function getProjectPaticipated() {
      if (props.user.businessProfile && props.user.businessProfile.projectPaticipated && props.user.businessProfile.projectPaticipated.length) {
        data.projectPaticipated = props.user.businessProfile.projectPaticipated.map(function (item) {
          return item.name;
        }).join(', ');
      }
    };

    var getSocialUrl = function getSocialUrl() {
      if (props.user.socialUrls) {
        data.socialUrls = props.user.socialUrls.filter(function (item) {
          return item.url;
        }).sort(function (item1, item2) {
          var index1 = data.sortOrderSocialUrls.findIndex(function (value) {
            return value === item1.name;
          });
          var index2 = data.sortOrderSocialUrls.findIndex(function (value) {
            return value === item2.name;
          });

          if (index1 < index2) {
            return -1;
          }

          if (index1 > index2) {
            return 1;
          }

          return 0;
        });
      }
    };

    var findSocialIcon = function findSocialIcon(item) {
      switch (item.name) {
        case SocialType.SOCIAL_FACEBOOK:
          return require('@/assets/feature/business-profile/icon_social_fb.svg');

        case SocialType.SOCIAL_VIBER:
          return require('@/assets/feature/business-profile/icon_social_viber.svg');

        case SocialType.SOCIAL_ZALO:
          return require('@/assets/feature/business-profile/icon_social_zalo.svg');

        case SocialType.SOCIAL_INSTAGRAM:
          return require('@/assets/feature/business-profile/icon_social_insta.svg');

        case SocialType.SOCIAL_TIKTOK:
          return require('@/assets/feature/business-profile/icon_social_tiktok.svg');

        case SocialType.SOCIAL_WEBSITE:
          return require('@/assets/feature/business-profile/icon_social_website.svg');

        default:
          return '';
      }
    };

    var getEmployeeSubtitle = function getEmployeeSubtitle(employee) {
      if (!employee) {
        return '';
      }

      var listSubtitle = [];

      if (employee.role) {
        listSubtitle.push(employee.role.name);
      } else {
        listSubtitle.push('Tư vấn viên');
      }

      if (employee.pos) {
        listSubtitle.push(employee.pos.name);
      }

      return listSubtitle.join(' - ');
    };

    var getBusinessProfileUrl = function getBusinessProfileUrl(employee) {
      return "".concat(window.location.protocol, "//").concat(window.location.hostname, "/business-profile/").concat(employee === null || employee === void 0 ? void 0 : employee.slugId);
    };

    var exportPDF = function exportPDF() {
      var node = document.getElementById('profile');
      htmlToImage.toPng(node).then(function (dataUrl) {
        fs.saveAs(dataUrl, 'profile.png');
      });
    };

    var copyBusinessProfileUrl = function copyBusinessProfileUrl() {
      var result = getBusinessProfileUrl(props.user);

      if (result) {
        data.clipboard = ref(result);
      }
    };

    var onClipboardSuccess = function onClipboardSuccess(e) {
      base.toast.success('Đã copy vào bộ nhớ tạm !!');
    };

    var getQRcodeCanvas = function getQRcodeCanvas() {
      QRCode.toCanvas(document.getElementById('canvas'), getBusinessProfileUrl(props.user), {
        errorCorrectionLevel: 'L'
      }, function () {// TODO
      });
    }; // Watch


    watch(function () {
      return props.user;
    }, function () {
      getProjectPaticipated();
      getSocialUrl();
      copyBusinessProfileUrl();
      getQRcodeCanvas();
    });
    watch(currentUser, function () {
      // Kiểm tra hồ sơ truy cập có phải của người dùng đang đăng nhập hay không?
      if (currentUser.value && props.slugId === currentUser.value.slugId) {
        // Chuyển hương sang trang Profile
        base.router.push({
          name: 'profile',
          query: {
            activeTab: 'business'
          }
        });
        return;
      }
    }); // Life cylce

    onMounted(function () {
      if (!props.user) {
        return;
      }

      getSocialUrl();
      getProjectPaticipated();
      copyBusinessProfileUrl();
      getQRcodeCanvas();
    });
    return {
      // Enum
      UserProfilePageSection: UserProfilePageSection,
      EmployeeStatusEnum: EmployeeStatusEnum,
      // Component props
      props: props,
      // Component reactive data
      data: data,
      // Methods
      formatDate: formatDate,
      getEmployeeSubtitle: getEmployeeSubtitle,
      getBusinessProfileUrl: getBusinessProfileUrl,
      getQRcodeCanvas: getQRcodeCanvas,
      findSocialIcon: findSocialIcon,
      getBackgroundImage: getBackgroundImage,
      exportPDF: exportPDF,
      goToInfoComponent: goToInfoComponent,
      copyBusinessProfileUrl: copyBusinessProfileUrl,
      onClipboardSuccess: onClipboardSuccess
    };
  }
});